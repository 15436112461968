import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';

export const StatusUpdateRedirect = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
      window.location.href = 'https://drive.google.com/drive/folders/16qyTr9HgsuaddtZiLfItSIZGU5H_VHDz?usp=share_link';
    }, [navigate]);
  
    return null;
};